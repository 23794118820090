<template>
  <v-app>
    <v-main class="pb-13">
      <router-view/>
    </v-main>
    <Navigation/>
    <Toast ref="ErrorEvents"/>
    <Toast ref="ErrorProjects"/>
    <Toast ref="ErrorUser"/>
    <Toast ref="ErrorRegister"/>
    <Toast ref="ErrorLogin"/>
    <Toast ref="Toast"/>
    <ModalSuccess ref="ModalSuccess"/>
  </v-app>
</template>

<script>
import Navigation from '@/components/Navigation';
import Toast from '@/components/modal/Toast';
import ModalSuccess from '@/components/modal/Success';
import {mapGetters} from "vuex";

export default {
  name: 'App',
  components: {
    Navigation,
    Toast,
    ModalSuccess
  },
  computed: {
    ...mapGetters([
      'jwt',
      'errorEvents',
      'errorProjects',
      'errorUser',
      'loadingEvent',
      'user'
    ])
  },
  methods: {
    showError(toast, value) {
      if (toast && value) {
        this.$refs[toast].show({
          color: 'error',
          icon: 'fa-solid fa-circle-xmark',
          title: 'Fehler',
          text: value,
          timeout: 99999
        });
      }
    }
  },
  created() {
    this.$store.dispatch('getCompany');
    this.$store.dispatch('getCategories');

    const jwt = localStorage.getItem('jwt');
    if (jwt) {
      this.$store.dispatch('login', {jwt: jwt});
    }
  },
  watch: {
    errorEvents(value) {
      this.showError('ErrorEvents', value);
    },
    errorProjects(value) {
      this.showError('ErrorProjects', value);
    },
    errorUser(value) {
      this.showError('ErrorUser', value);
    },
    loadingEvent(newValue, oldValue) {
      if (oldValue && !newValue) {
        this.$refs.ModalSuccess.setState(true);
      }
    }
  }
}
</script>

<style lang="scss">
@import "./src/styles/main.scss";
</style>